.embla-main-display {
  --slide-spacing: 0px;
  --slide-size: 100%;
  --slide-height: 450px;
  position: relative;
}
.embla-promotion {
  --slide-spacing: 0px;
  --slide-size: 100%;
  width: 100%;
}
.embla-feed {
  --slide-spacing: 0;
  --slide-size: 100%;
  --slide-height: 28rem;
  position: relative;
}
.embla-product {
  --slide-spacing: 0.3rem;
  --slide-size: 95%;
  --slide-height: auto;
}
.embla-middle-display {
  --slide-spacing: 0.8rem;
  --slide-size: 50%;
  /* --slide-height: 19rem; */
  /* background-color: black; */
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
/* .embla-middle-display .embla__container {
  border-radius: 20px;
  overflow: hidden;
} */
.embla_slide-full {
  flex: 0 0 100%;
  padding-top: 5px;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
/* .embla-middle-display .embla__slide {
  border-radius: 20px;
} */
.embla__slide__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  height: var(--slide-height);
}
/* .embla-middle-display .embla__slide__img {
  border: 2px solid black;
} */
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla__parallax {
  height: 100%;
  overflow: hidden;
}
.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
}
.embla__parallax__img {
  max-width: none;
  width: calc(100% + (var(--slide-spacing) * 2));
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.embla-feed .embla__dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
}
.embla__dots {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  height: 1rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.embla__dot:after {
  background: rgba(255, 255, 255, 0.477);
  border-radius: 0.5rem;
  width: 0.4rem;
  height: 0.4rem;
  content: '';
}
.embla-promotions-dot:after {
  background: gray;
  border-radius: 0.5rem;
  width: 0.4rem;
  height: 0.4rem;
  content: '';
}

.embla__dot--selected:after {
  background: white;
  width: 1.2rem;
}
.embla_dot--selected:after {
  background: black;
  width: 1.2rem;
}

@media screen and (min-width: 800px) {
  .embla-main-display {
    --slide-spacing: 10px;
    --slide-size: 40%;
    /* --slide-height: 500px; */
    border-radius: 12px;
    overflow: hidden;
  }
  .embla-middle-display {
    border-radius: 12px;
    overflow: hidden;
  }
  .embla-product {
    border-radius: 10px;
    overflow: hidden;
  }
}
